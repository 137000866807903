import React, { Fragment, useContext } from 'react';
import { LanguageContext } from '../../../../container/Language';
import { Rrss } from '../../../../entities/Localization';
import facebook from '../../../../images/facebook.png';
import instagram from '../../../../images/instagram.png';
import instagramImg1 from '../../../../images/RRSS_IG_01_TDP.png';
import instagramImg2 from '../../../../images/RRSS_IG_02_TDP.png';
import instagramImg3 from '../../../../images/RRSS_IG_03_TDP.png';
import instagramImg4 from '../../../../images/RRSS_IG_04_TDP.png';
import instagramImg5 from '../../../../images/RRSS_IG_05_TDP.png';
import instagramImg6 from '../../../../images/RRSS_IG_06_TDP.png';
import fcImg1 from '../../../../images/RRSS_FC_01_TDP.png';
import fcImg2 from '../../../../images/RRSS_FC_02_TDP.png';
import fcImg3 from '../../../../images/RRSS_FC_03_TDP.png';
import fcImg4 from '../../../../images/RRSS_FC_04_TDP.png';

import './style.scss';

function RRSS() {
  window.scrollTo(0, 0);

  const openFacebook = () => {
    window.open(
      'https://www.facebook.com/RestaurantePablosCostaTeguise',
      '_blank',
    );
  };

  const openInstagram = () => {
    window.open('https://www.instagram.com/pablos.restaurante/', '_blank');
  };

  const languageContext = useContext(LanguageContext);

  const localization: Rrss = Object.assign(
    {},
    languageContext.dictionary.home.rrss,
  );

  return (
    <Fragment>
      <div className="container">
        <div className="titleRRSS">
          <h1 className="headerTitleRRSS">{localization.title}</h1>
          <h2 className="headerSubtitleRRSS">{localization.subtitle}</h2>
        </div>
        <div className="row justify-content-center mb-5">
          <div className="col-md-6 mt-4">
            <div className="rowTitle">
              <h2 className="headerRRSS">FACEBOOK</h2>
            </div>
            <div className="row no-gutters">
              <div className="col leftColumn">
                <img
                  src={fcImg1}
                  className="rssImg"
                  alt=""
                  onClick={() => openFacebook()}
                />
              </div>
              <div className="col">
                <img
                  src={fcImg2}
                  className="rssImg"
                  alt=""
                  onClick={() => openFacebook()}
                />
              </div>
            </div>
            <div className="row no-gutters rowRRSS">
              <div className="col leftColumn">
                <img
                  src={fcImg3}
                  className="rssImg"
                  alt=""
                  onClick={() => openFacebook()}
                />
              </div>
              <div className="col">
                <img
                  src={fcImg4}
                  className="rssImg"
                  alt=""
                  onClick={() => openFacebook()}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="rowTitle">
              <h2 className="headerRRSS">INSTAGRAM</h2>
            </div>
            <div className="row no-gutters">
              <div className="col leftColumn">
                <img
                  src={instagramImg1}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
              <div className="col leftColumn">
                <img
                  src={instagramImg2}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
              <div className="col">
                <img
                  src={instagramImg3}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
            </div>
            <div className="row no-gutters rowRRSS">
              <div className="col leftColumn">
                <img
                  src={instagramImg4}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
              <div className="col leftColumn">
                <img
                  src={instagramImg5}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
              <div className="col">
                <img
                  src={instagramImg6}
                  className="rssImg"
                  alt=""
                  onClick={() => openInstagram()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default RRSS;
