import React, { Fragment, useContext } from 'react';
import { LanguageContext } from '../../container/Language';
import { Local } from '../../entities/Localization';
import ActionButton from '../../common_components/actionButton/index';

import './style.scss';

function Restaurant() {
  document.title = 'Local Restaurante Pablos | Costa Teguise';

  window.scrollTo(0, 0);
  const languageContext = useContext(LanguageContext);

  const localization: Local = Object.assign(
    {},
    languageContext.dictionary.local,
  );
  return (
    <Fragment>
      <div className="container">
        <div className="restaurantHeader">
          <div className="titleMenu">
            <h1 className="headerTitle">{localization.title}</h1>
            <h2 className="headerSubtitle">{localization.subtitle}</h2>
            <p className="headerRestText">{localization.text}</p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="imgContainer">
          <div className="row no-gutters">
            <div className="col-sm">
              <div className="row no-gutters">
                <div className="col columRestOne">
                  <img alt="" className="change-res-img-1" />
                </div>
              </div>
              <div className="row no-gutters secondRowRes">
                <div className="col-5 columRestTwo">
                  <img alt="" className="change-res-img-2" />
                </div>
                <div className="col-7 columRestThree">
                  <img alt="" className="change-res-img-3" />
                </div>
              </div>
            </div>
            <div className="col-sm secondColRes">
              <div className="row no-gutters">
                <div className="col-md columRestOne">
                  <img alt="" className="change-res-img-4" />
                </div>
              </div>
              <div className="row no-gutters secondRowRes">
                <div className="col-5 columRestTwo">
                  <img alt="" className="change-res-img-5" />
                </div>
                <div className="col-7 columRestThree">
                  <img alt="" className="change-res-img-6" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-sm">
            <div className="row">
              <div className="col">
                <img src={img1} className="restaurantImg" alt="" />
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <img src={img3} className="restaurantImg" alt="" />
              </div>
              <div className="col-7">
                <img src={img4} className="restaurantImg" alt="" />
              </div>
            </div>
          </div>

          <div className="col-sm">
            <div className="row">
              <div className="col">
                <img src={img1} className="restaurantImg" alt="" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <img src={img3} className="restaurantImg" alt="" />
              </div>
              <div className="col">
                <img src={img4} className="restaurantImg" alt="" />
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <ActionButton />
    </Fragment>
  );
}

export default Restaurant;
