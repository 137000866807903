import React, { Fragment, useContext } from 'react';
import { LanguageContext } from '../../../../container/Language';
import { HeaderSection } from '../../../../entities/Localization';
import carouselimg from '../../../../images/carousel.jpg';
import './style.scss';

function Header() {
  window.scrollTo(0, 0);
  const languageContext = useContext(LanguageContext);

  const localization: HeaderSection = Object.assign(
    {},
    languageContext.dictionary.home.headerSection,
  );
  return (
    <Fragment>
      <div className="header"></div>
      <div className="title">
        <h1 className="headerTitle">{localization.title}</h1>
        <h2 className="headerSubtitle">{localization.subtitle}</h2>
        <p className="headerText">{localization.text}</p>
      </div>
    </Fragment>
  );
}

export default Header;
