import React, { Fragment, useContext } from 'react';
import { LanguageContext } from '../../container/Language';
import { Link } from 'react-router-dom';
import { MenuPage } from '../../entities/Localization';
import ActionButton from '../../common_components/actionButton/index';
import MenuComponent from '../../common_components/menu/index';
import './style.scss';

function Menu() {
  document.title = 'Carta Restaurante Pablos | Costa Teguise';

  window.scrollTo(0, 0);

  const languageContext = useContext(LanguageContext);

  const localization: MenuPage = Object.assign(
    {},
    languageContext.dictionary.menuPage,
  );
  return (
    <Fragment>
      <div className="container">
        <div className="restaurantHeader">
          <div className="titleMenu">
            <h1 className="headerTitle">{localization.title}</h1>
            <h2 className="headerSubtitle"></h2>
            <p className="headerMenuText">{localization.text}</p>
          </div>
        </div>
      </div>
      <MenuComponent />
      <Link to="/menuQR">
        <a className="seeAll">CARTA COMPLETA</a>
      </Link>
      <ActionButton />
    </Fragment>
  );
}

export default Menu;
