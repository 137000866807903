import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import logo from '../../images/logomain.png';
import facebook from '../../images/facebook-black.png';
import instagram from '../../images/instagram-black.png';

var white = false;
var collp = false;

function NavBar() {
  const openFacebook = () => {
    window.open(
      'https://www.facebook.com/RestaurantePablosCostaTeguise',
      '_blank',
    );
  };

  const openInstagram = () => {
    window.open('https://www.instagram.com/pablos.restaurante/', '_blank');
  };

  const openMaps = () => {
    window.open('https://goo.gl/maps/U38is1DDdWxRNFrZ8', '_blank');
  };

  const handleScroll = () => {
    if (window.scrollY > 20 && white === false && collp === false) {
      document.querySelector('.navbar')!.className =
        'navbar navbar-expand-lg navbar-light fixed-top scroll';
      white = true;
    } else if (window.scrollY < 20 && white === true && collp === false) {
      document.querySelector('.navbar')!.className =
        'navbar navbar-expand-lg navbar-light fixed-top menuNav';
      white = false;
    }
  };

  const togg = () => {
    if (collp === false) {
      document.querySelector('.navbar')!.className =
        'navbar navbar-expand-lg navbar-light fixed-top scroll';
      white = true;
      collp = true;
    } else {
      collp = false;
      document.querySelector('.navbar')!.className =
        'navbar navbar-expand-lg navbar-light fixed-top scroll';
      white = false;
    }
  };

  const changeWhite = () => {
    document.querySelector('.navbar')!.className =
      'navbar navbar-expand-lg navbar-light fixed-top scroll';
    white = true;
    collp = false;
  };

  const changeTrans = () => {
    document.querySelector('.navbar')!.className =
      'navbar navbar-expand-lg navbar-light fixed-top menuNav';
    white = false;
    collp = false;
  };

  window.addEventListener('scroll', handleScroll);

  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top menuNav">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="logoImg" alt="..." />
          </Link>
          {/* <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Dropdown
          </a> */}

          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <a className="dropdown-item" href="#">
              <a className="nav-link">HOME</a>
            </a>
            <a className="dropdown-item" href="#">
              <a className="nav-link">HOME</a>
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={togg}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav menuItems">
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarNav"
              >
                <Link to="/">
                  <a className="nav-link menuNavText" onClick={changeTrans}>
                    HOME
                  </a>
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarNav"
              >
                <Link to="/restaurant">
                  <a className="nav-link menuNavText" onClick={changeWhite}>
                    LOCAL
                  </a>
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarNav"
              >
                <Link to="/menu">
                  <a className="nav-link menuNavText" onClick={changeWhite}>
                    CARTA
                  </a>
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link to="/blog">
                  <a
                    className="nav-link menuNavText"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                  >
                    Blog
                  </a>
                </Link>
              </li> */}
              <div className="mobileMenuCont">
                <a className="actionButtons" href="tel:+34 659 018 061">
                  RESERVAR
                </a>
                <a className="actionButtons" onClick={openMaps}>
                  CÓMO LLEGAR
                </a>
                <div className="row justify-content-center mt-5">
                  <div className="col-2">
                    <img
                      src={facebook}
                      className="socialImg"
                      alt="..."
                      onClick={() => openFacebook()}
                    />
                  </div>
                  <div className="col-2">
                    {' '}
                    <img
                      src={instagram}
                      className="socialImg"
                      alt="..."
                      onClick={() => openInstagram()}
                    />
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </Fragment>
  );
}

export default NavBar;
