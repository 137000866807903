import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import facebook from '../../images/facebook.png';
import instagram from '../../images/instagram.png';
import trip from '../../images/tripadvisor.png';
import logoimg from '../../images/logofooter.png';

function ActionButton() {
  const openFacebook = () => {
    window.open('https://www.facebook.com/arroceriatabernadelpuerto', '_blank');
  };

  const openInstagram = () => {
    window.open(
      'https://www.instagram.com/restaurante_taberna_del_puerto/',
      '_blank',
    );
  };

  const openTrip = () => {
    window.open(
      'https://www.tripadvisor.es/Restaurant_Review-g673239-d2077448-Reviews-La_Taberna_del_Puerto-Puerto_Calero_Lanzarote_Canary_Islands.html?m=19905',
      '_blank',
    );
  };

  const openMaps = () => {
    window.open('https://goo.gl/maps/R2n2BrfQ7mRWbajV7', '_blank');
  };

  return (
    <a href="tel:+34 659 018 061" className="float" target="_blank">
      <i className="fa fa-phone my-float"></i>
    </a>
  );
}

export default ActionButton;
