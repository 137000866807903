import React, { Fragment, useContext, useEffect, useState } from 'react';
import './style.scss';
import { LanguageContext } from '../../container/Language';
import { MenuQRData } from '../../entities/Localization';
import espanaimg from '../../images/espana.png';
import alemaniaimg from '../../images/alemania.png';
import granbretañaimg from '../../images/gran-bretaña.png';
import logoimg from '../../images/logo-pablos.png';
import arrocesimg from '../../images/arroces.png';
import calientesimg from '../../images/calientes.png';
import carnesimg from '../../images/carnes.png';
import ensaladasimg from '../../images/ensaladas.png';
import entrantesimg from '../../images/entrantes.png';
import menuninosimg from '../../images/menu-ninos.png';
import pastasimg from '../../images/pastas.png';
import pescadosimg from '../../images/pescados.png';
import postresimg from '../../images/postres.png';
import sugerenciasimg from '../../images/sugerencias.png';
import cafeimg from '../../images/cafes.png';
import pescadogrillimg from '../../images/pescado-grill.png';
import comidasimg from '../../images/comidas.png';
import bebidasimg from '../../images/bebidas.png';
import aguaimg from '../../images/agua.png';
import alcohoimg from '../../images/alcoho.png';
import champagneimg from '../../images/champagne.png';
import noalcohoimg from '../../images/no-alcoho.png';
import vinoimg from '../../images/vino.png';
import zumoimg from '../../images/zumo.png';
import sopaimg from '../../images/sopa.png';
import pizzaimg from '../../images/pizza.png';
import asadosimg from '../../images/asados.png';

function MenuQR() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [tabPanel, setTabPanel] = useState({
    panel: 'food' || 'drinks',
  });
  const [tabIsExpanded, setTabIsExpanded] = useState({
    foodTabIsActive: true || false,
    drinksTabIsActive: false || true,
    foodTabState: 'active',
    drinksTabState: '',
  });

  const onClickFood = (): void => {
    tabPanel.panel === 'drinks' && setTabPanel({ panel: 'food' });
    setTabIsExpanded({
      foodTabIsActive: true,
      drinksTabIsActive: false,
      foodTabState: 'active',
      drinksTabState: '',
    });
  };

  const onClickDrinks = (): void => {
    tabPanel.panel === 'food' && setTabPanel({ panel: 'drinks' });
    setTabIsExpanded({
      foodTabIsActive: false,
      drinksTabIsActive: true,
      foodTabState: '',
      drinksTabState: 'active',
    });
  };

  const languageContext = useContext(LanguageContext);

  const handleENLanguageChange = () => {
    // set selected language by calling context method
    languageContext.setLanguage('en');
    console.log(languageContext);
  };

  const handleESLanguageChange = () => {
    // set selected language by calling context method
    languageContext.setLanguage('es');
    console.log(languageContext);
  };

  const handleDELanguageChange = () => {
    // set selected language by calling context method
    languageContext.setLanguage('de');
    console.log(languageContext);
  };

  const menu: MenuQRData = Object.assign({}, languageContext.dictionary.menuQR);
  let images = [
    calientesimg,
    entrantesimg,
    ensaladasimg,
    sopaimg,
    pescadogrillimg,
    arrocesimg,
    pastasimg,
    carnesimg,
    asadosimg,
    pizzaimg,
    menuninosimg,
    postresimg,
    cafeimg,
  ];
  let drinksImages = [
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    vinoimg,
    champagneimg,
    aguaimg,
    noalcohoimg,
    alcohoimg,
    zumoimg,
  ];
  const createTable = () => {
    let table = [] as any;

    for (let i = 0; i < menu.menu.length; i++) {
      table.push(
        <thead className="thead">
          <tr>
            <th scope="col" colSpan={2}>
              <div className="main_title">
                <img className="icon-image" src={images[i]} />
                <h4 className="main_title-text">{menu.menu[i].section}</h4>
              </div>
            </th>
          </tr>
        </thead>,
      );
      for (let j = 0; j < menu.menu[i].items.length; j++) {
        table.push(
          <tbody className="tbody">
            <tr>
              <td>{menu.menu[i].items[j].name}</td>
              <td>{menu.menu[i].items[j].price}</td>
            </tr>
          </tbody>,
        );
      }
    }
    return table;
  };

  const createDrinksTable = () => {
    let table = [] as any;

    for (let i = 0; i < menu.drinks.length; i++) {
      table.push(
        <thead className="thead">
          <tr>
            <th scope="col" colSpan={4}>
              <div className="main_title">
                <img className="icon-image" src={drinksImages[i]} />
                <h4 className="main_title-text">{menu.drinks[i].section}</h4>
              </div>
            </th>
          </tr>
        </thead>,
      );
      for (let j = 0; j < menu.drinks[i].items.length; j++) {
        table.push(
          <tbody className="tbody">
            <tr>
              <td colSpan={2}>{menu.drinks[i].items[j].name}</td>
              <td>{menu.drinks[i].items[j].price}</td>
            </tr>
          </tbody>,
        );
      }
    }
    return table;
  };
  return (
    <Fragment>
      <div className="tabs">
        <div className="language-row">
          <a onClick={handleESLanguageChange}>
            <img className="icon-image" src={espanaimg} />
          </a>
          <a onClick={handleDELanguageChange}>
            <img className="icon-image" src={alemaniaimg} />
          </a>
          <a onClick={handleENLanguageChange}>
            <img className="icon-image" src={granbretañaimg} />
          </a>
        </div>

        <div className="logo"></div>
        <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
          <li className="nav-item" onClick={onClickFood}>
            <a
              className={`nav-link ${tabIsExpanded.foodTabState}`}
              id="home-tab"
              data-toggle="tab"
              // href="#home"
              role="tab"
              aria-controls="home"
              aria-selected={tabIsExpanded.foodTabIsActive}
            >
              <img className="icon-image" src={comidasimg} />
              {menu.tab1}
            </a>
          </li>
          <li className="nav-item" onClick={onClickDrinks}>
            <a
              className={`nav-link ${tabIsExpanded.drinksTabState}`}
              id="profile-tab"
              data-toggle="tab"
              // href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected={tabIsExpanded.drinksTabIsActive}
            >
              <img className="icon-image" src={bebidasimg} />
              {menu.tab2}
            </a>
          </li>
        </ul>
      </div>
      <div className="table-responsive table-borderless menuQR">
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <table className="table">
              {tabPanel.panel === 'food' ? createTable() : createDrinksTable()}
            </table>
          </div>
          {/* <div
            className="tab-pane fade show active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <table className="table">
              {createDrinksTable()}
            </table>
          </div> */}
        </div>
      </div>
      <div className="footer">
        <p>{menu.tax}</p>
        <p>
          {menu.inf1}{' '}
          <a href={menu.info1url} target="_blank">
            {menu.inf1see}
          </a>
        </p>

        <p>{menu.inf2}</p>
      </div>
    </Fragment>
  );
}

export default MenuQR;
