import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';
import facebook from '../../images/facebook.png';
import instagram from '../../images/instagram.png';
import logoimg from '../../images/logofooter.png';
import gobiernoespana from '../../images/gobiernoespana.png';
import gobiernocanarias from '../../images/gobiernocanarias.png';
import europa from '../../images/europa.png';

function Footer() {
  const openFacebook = () => {
    window.open(
      'https://www.facebook.com/RestaurantePablosCostaTeguise',
      '_blank',
    );
  };

  const openInstagram = () => {
    window.open('https://www.instagram.com/pablos.restaurante/', '_blank');
  };

  const openTrip = () => {
    window.open(
      'https://www.tripadvisor.es/Restaurant_Review-g673239-d2077448-Reviews-La_Taberna_del_Puerto-Puerto_Calero_Lanzarote_Canary_Islands.html?m=19905',
      '_blank',
    );
  };

  const openMaps = () => {
    window.open('https://goo.gl/maps/U38is1DDdWxRNFrZ8', '_blank');
  };

  return (
    <Fragment>
      <div className="footerMain">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-5">
                  <div className="row mt-2 rowButtonLinks">
                    <div className="col footerLinks">
                      <Link to="/">
                        <a className="linkText">Home</a>
                      </Link>
                    </div>
                    <div className="col footerLinks">
                      <Link to="/menu">
                        <p className="linkText">Carta</p>
                      </Link>
                    </div>
                    <div className="col footerLinks">
                      <Link to="/restaurant">
                        <p className="linkText">Local</p>
                      </Link>
                    </div>
                  </div>
                  <div className="row rowButtonRRSS">
                    <img
                      src={facebook}
                      className="socialImg"
                      alt="..."
                      onClick={() => openFacebook()}
                    />

                    <img
                      src={instagram}
                      className="socialImg"
                      alt="..."
                      onClick={() => openInstagram()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="">
                <img className="logoFooter" src={logoimg}></img>
              </div>
            </div>

            <div className="col-md-5 colButtonFooter">
              <div className="row">
                <div className="col">
                  <div className="rowButtonFooter">
                    <a
                      className="rowButtonTextFooter"
                      href="tel:+34 659 018 061"
                    >
                      RESERVAR
                    </a>
                  </div>
                  <p className="footerText">
                    Horario: Lunes - Domingo 11:00-23:00
                  </p>
                </div>
                <div className="col">
                  <div className="rowButtonFooter" onClick={() => openMaps()}>
                    <p className="rowButtonTextFooter">CÓMO LLEGAR</p>
                  </div>
                  <p className="footerText">
                    Calle la Driza, 1, 35508 Costa Teguise, Las Palmas
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col">
              <div className="row rowGob">
                <img
                  src={gobiernoespana}
                  className="gobImg"
                  alt="..."
                  onClick={() => openFacebook()}
                />
                <img
                  src={gobiernocanarias}
                  className="gobcanImg"
                  alt="..."
                  onClick={() => openFacebook()}
                />
                <img
                  src={europa}
                  className="eurImg"
                  alt="..."
                  onClick={() => openFacebook()}
                />
              </div>
              <div className="row">
                <a className="textoGob" href="/transparencia.pdf">
                  «Empresa beneficiaria de la Línea COVID de ayudas directas a
                  personas autónomas y empress en apoyo a la solvencia
                  empresarial en respuesta a la pandemia de la Covid-19
                  financiadas por el Gobierno de España y el Gobierno de
                  Canarias.»
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Footer;
