import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../container/Language';
import { MenuSection } from '../../entities/Localization';

import './style.scss';

function MenuComponent() {
  const languageContext = useContext(LanguageContext);

  const localization: MenuSection = Object.assign(
    {},
    languageContext.dictionary.home.menuSection,
  );

  return (
    <Fragment>
      <div className="container">
        <div className="Delicious_area">
          <div className="tablist_menu">
            <div className="row">
              <div className="col-xl-12">
                <ul
                  className="nav justify-content-center flex-sm-row"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-caribean-tab"
                      data-toggle="pill"
                      href="#pills-caribean"
                      role="tab"
                      aria-controls="pills-caribean"
                      aria-selected="true"
                    >
                      <div className="single_menu text-center">
                        <h4>{localization.menu1}</h4>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-special-tab"
                      data-toggle="pill"
                      href="#pills-special"
                      role="tab"
                      aria-controls="pills-special"
                      aria-selected="false"
                    >
                      <div className="single_menu text-center">
                        <h4>{localization.menu2}</h4>
                      </div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-classic-tab"
                      data-toggle="pill"
                      href="#pills-classic"
                      role="tab"
                      aria-controls="pills-classic"
                      aria-selected="false"
                    >
                      <div className="single_menu text-center">
                        <h4>{localization.menu3}</h4>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row justify-content-center pb-3">
            <div className="col-md-12">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-caribean"
                  role="tabpanel"
                  aria-labelledby="pills-caribean-tab"
                >
                  <div className="row no-gutters">
                    <div className="col">
                      <div className="row no-gutters">
                        <div className="col-sm-5 columOne">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-en-img-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-7 columTwo">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-en-img-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row no-gutters">
                        <div className="col-sm-5 columThree">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-en-img-3"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-7 columFour">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-en-img-4"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-special"
                  role="tabpanel"
                  aria-labelledby="pills-special-tab"
                >
                  <div className="row no-gutters">
                    <div className="col">
                      <div className="row no-gutters">
                        <div className="col-md-5 columOne">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-pr-img-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 columTwo">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-pr-img-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row no-gutters">
                        <div className="col-md-5 columThree">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-pr-img-3"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 columFour">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-pr-img-4"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-classic"
                  role="tabpanel"
                  aria-labelledby="pills-classic-tab"
                >
                  <div className="row no-gutters">
                    <div className="col">
                      <div className="row no-gutters">
                        <div className="col-md-5 columOne">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-ds-img-1"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 columTwo">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-ds-img-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row no-gutters">
                        <div className="col-md-5 columThree">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-ds-img-3"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 columFour">
                          <div className="single_delicious d-flex align-items-center">
                            <div className="thumb">
                              <img
                                alt=""
                                className="productImg change-ds-img-4"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default MenuComponent;
