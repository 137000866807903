import React, { Fragment } from 'react';
import carouselimg1 from '../../images/HOME1.png';
import carouselimg2 from '../../images/HOME2.png';
import carouselimg3 from '../../images/HOME3.png';
import carouselimg4 from '../../images/HOME4.png';

import './style.scss';

function SpecialitiesSlider() {
  window.scrollTo(0, 0);
  return (
    <Fragment>
      <div className="container">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={carouselimg1} className="carouselImg" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={carouselimg2} className=" carouselImg" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={carouselimg3} className=" carouselImg" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={carouselimg4} className=" carouselImg" alt="..." />
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </Fragment>
  );
}

export default SpecialitiesSlider;
