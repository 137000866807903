import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { LanguageProvider } from '../container/Language';
import NavBar from '../common_components/navBar/index';
import Footer from '../common_components/footer/index';
import Home from '../pages/home/index';
import Restaurant from '../pages/restaurant/index';
import Menu from '../pages/menuPage/index';
import MenuQR from '../pages/menuQR/index';
import './App.scss';

function App() {
  return (
    <LanguageProvider>
      <Router>
        <NavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/restaurant" component={Restaurant} />
          <Route exact path="/menu" component={Menu} />
          <Route exact path="/menuQR" component={MenuQR} />
        </Switch>
        <Footer />
      </Router>
    </LanguageProvider>
  );
}

export default App;
