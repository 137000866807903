import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../container/Language';
import { MenuSection } from '../../entities/Localization';
import Header from './components/header/index';
import ActionButton from '../../common_components/actionButton/index';
import Slider from './components/slider/index';
import MenuComponent from '../../common_components/menu/index';
import SpecialitiesSlider from '../../common_components/specialtiesSlider/index';
import RRSS from './components/rrss/index';
import './style.scss';

function Home() {
  document.title = 'Restaurante Pablos | Costa Teguise';
  window.scrollTo(0, 0);
  const languageContext = useContext(LanguageContext);

  const localization: MenuSection = Object.assign(
    {},
    languageContext.dictionary.home.menuSection,
  );
  return (
    <Fragment>
      <Header />
      <SpecialitiesSlider />
      <div className="titleMenu">
        <h1 className="headerTitleMenu">{localization.title}</h1>
        <h2 className="headerSubtitleMenu">{localization.subtitle}</h2>
      </div>
      <MenuComponent />
      <Link to="/menu">
        <a className="seeAll">VER CARTA</a>
      </Link>
      <RRSS />
      <ActionButton />
    </Fragment>
  );
}

export default Home;
